import { Link } from "react-router-dom";

function Home() {
  return (
    <>
      <main>
        <Link to="/about">
          <div className="page-link">
            <h1 className="title-absolute">Callum Kiwi</h1>
            <img
              alt="Kiwi bird viewed from the side"
              className="max-width-100"
              src="https://i.natgeofe.com/n/1fbea594-3526-4c6e-9285-fff46958507f/01-kiwi-species.jpg?w=1260&h=839"
            />
          </div>
        </Link>
      </main>
      <nav></nav>
    </>
  );
}

export default Home;
